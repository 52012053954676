import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import LogoBottom from "../../static/images/bottom_symbol.svg"
import Img from "gatsby-image"

const Footer = () => {
  const query = useStaticQuery(graphql`
    query {
      pci_comp: file(relativePath: { eq: "pci_dss_small_compressed.png" }) {
        childImageSharp {
          fixed(width: 135) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <footer
      className="footer footer-light"
      style={{ backgroundColor: "#287224" }}
    >
      <div className="container d-flex flex-column flex-md-row align-items-center text-center text-md-left ">
        <Link to="/">
          <LogoBottom
            style={{
              width: 150,
            }}
          />
        </Link>
        <div className="fs-sm align-self-center text-white">
          <div className="fw-bold">
            For more information about CleanPay, contact us on:{" "}
            <a className="text-white fw-bold" href="mailto:connect@cleanpay.eco">
              connect@cleanpay.eco
            </a>
          </div>
          <div>
            <Link to="./privacy" className="text-white">
              Read our <span className="fw-bold"> privacy policy </span>here.
            </Link>
          </div>
          <div>&copy; Copyright 2025</div>
        </div>
        <div className="ml-md-auto mr-md-0 mx-auto mt-6 mt-md-0">
          <a href="../../static/images/pci.pdf" target="_blank" rel="noopener noreferrer">
            <Img
              style={{ boxShadow: "0 0 12px rgba(0,0,0,.5)", borderRadius: 3 }}
              fixed={query.pci_comp.childImageSharp.fixed}
            />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
